import { EditableField } from 'datatalks-ui';

export default emailBuilder => {
	const pluginOpts = emailBuilder.getOptions();

	const { title, titleOnChange } = pluginOpts.titleConfig;

	return new EditableField({
		value: title,
		onSave: titleOnChange
	}).getEl();
};
