import { merge } from 'datatalks-utils';

/**
 * Converts a selector to its corresponding value in the email builder's elementSelectors object, if available.
 * If no corresponding value is found, the original selector is returned.
 *
 * @param {string} selector - The selector to be converted.
 * @return {string} - The converted selector or the original selector if no conversion is found.
 */
function convertSelector(selector) {
	switch (selector) {
		case 'paragraph':
			return 'p';
			break;

		default:
			return selector;
			break;
	}
}

/* eslint-disable require-jsdoc */
function textEditorUpdateColorsOnGeneralChange(
	editor,
	textEditor,
	options = {}
) {
	const defaults = {
		isButton: false
	};

	options = merge(defaults, options);

	function propHasValue(el, prop) {
		el = convertSelector(el);
		return !!editor.getStyleRules(el, prop);
	}

	function getPropValue(el, prop) {
		return options.isButton && el != 'wrapper'
			? editor.getStyleRules('button', prop)
			: editor.getStyleRules(el, prop);
	}

	editor.on('run:change-general-style-prop', (edt, sender) => {
		if (sender.changedProp === 'all')
			textEditor.setOptions(textEditorCommonOptions(editor, options));

		if (sender.changedProp === 'color') {
			if (!sender.selector || sender.selector === 'wrapper') {
				const editorOptions = {
					iframeStyleOptions: {}
				};

				['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(tag => {
					if (!propHasValue(tag, 'color'))
						editorOptions.iframeStyleOptions[tag] = {
							color: sender.newValue
						};
				});

				textEditor.setOptions(editorOptions);
			} else {
				textEditor.setOptions({
					iframeStyleOptions: {
						[convertSelector(sender.selector)]: {
							color: sender.newValue
						}
					}
				});
			}
		}

		if (sender.changedProp === 'fontSize')
			textEditor.setOptions({
				iframeStyleOptions: {
					[convertSelector(sender.selector)]: {
						'font-size': sender.newValue
					}
				}
			});
		if (sender.changedProp === 'fontFamily') {
			if (options.isButton) {
				const editorOptions = { iframeStyleOptions: {} };
				['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(tag => {
					editorOptions.iframeStyleOptions[tag] = {
						'font-family': sender.newValue
					};
				});
				textEditor.setOptions(editorOptions);
			} else {
				textEditor.setOptions({
					iframeStyleOptions: {
						[sender.selector]: {
							'font-family': sender.newValue
						}
					}
				});
			}
		}
		if (sender.changedProp === 'linksColor')
			textEditor.setOptions({
				iframeStyleOptions: {
					link: {
						color: sender.newValue
					}
				}
			});

		if (sender.changedProp === 'lineHeight') {
			if (!sender.selector || sender.selector === 'wrapper') {
				const editorOptions = {
					iframeStyleOptions: {}
				};

				['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(tag => {
					if (!propHasValue(tag, 'line-height'))
						editorOptions.iframeStyleOptions[tag] = {
							['line-height']: sender.newValue
						};
				});

				textEditor.setOptions(editorOptions);
			} else {
				if (sender.newValue) {
					textEditor.setOptions({
						iframeStyleOptions: {
							[convertSelector(sender.selector)]: {
								'line-height': sender.newValue
							}
						}
					});
				} else {
					textEditor.setOptions({
						iframeStyleOptions: {
							[convertSelector(sender.selector)]: {
								'line-height': getPropValue(
									'wrapper',
									'line-height'
								)
							}
						}
					});
				}
			}
		}

		if (sender.changedProp === 'buttonTextColor' && options.isButton)
			textEditor.setOptions({
				iframeStyleOptions: {
					p: {
						color: sender.newValue
					}
				}
			});
	});
}

const textEditorCommonOptions = (editor, options = {}) => {
	const defaults = {
		isButton: false
	};

	options = merge(defaults, options);

	const isButton = options.isButton;

	function getPropValue(el, prop) {
		return isButton && el != 'wrapper'
			? editor.getStyleRules('button', prop)
			: editor.getStyleRules(el, prop);
	}

	const commonOptions = {
		iframeStyleOptions: {
			p: {
				color:
					getPropValue('paragraph', 'color') ||
					getPropValue('wrapper', 'color'),
				'font-size': getPropValue('paragraph', 'font-size'),
				'line-height':
					getPropValue('paragraph', 'line-height') ||
					getPropValue('wrapper', 'line-height'),
				'font-family':
					getPropValue('paragraph', 'font-family') ||
					getPropValue('wrapper', 'font-family')
			},
			h1: {
				color:
					getPropValue('h1', 'color') ||
					getPropValue('wrapper', 'color'),
				'font-size': editor.getStyleRules('h1', 'font-size'),
				'line-height':
					getPropValue('h1', 'line-height') ||
					getPropValue('wrapper', 'line-height'),
				'font-family':
					getPropValue('h1', 'font-family') ||
					getPropValue('wrapper', 'font-family')
			},
			h2: {
				color:
					getPropValue('h2', 'color') ||
					getPropValue('wrapper', 'color'),
				'font-size': editor.getStyleRules('h2', 'font-size'),
				'line-height':
					getPropValue('h2', 'line-height') ||
					getPropValue('wrapper', 'line-height'),
				'font-family':
					getPropValue('h2', 'font-family') ||
					getPropValue('wrapper', 'font-family')
			},
			h3: {
				color:
					getPropValue('h3', 'color') ||
					getPropValue('wrapper', 'color'),
				'font-size': editor.getStyleRules('h3', 'font-size'),
				'line-height':
					getPropValue('h3', 'line-height') ||
					getPropValue('wrapper', 'line-height'),
				'font-family':
					getPropValue('h3', 'font-family') ||
					getPropValue('wrapper', 'font-family')
			},
			h4: {
				color:
					getPropValue('h4', 'color') ||
					getPropValue('wrapper', 'color'),
				'font-size': editor.getStyleRules('h4h', 'font-size'),
				'line-height':
					getPropValue('h4', 'line-height') ||
					getPropValue('wrapper', 'line-height'),
				'font-family':
					getPropValue('h4', 'font-family') ||
					getPropValue('wrapper', 'font-family')
			},
			h5: {
				color:
					getPropValue('h5', 'color') ||
					getPropValue('wrapper', 'color'),
				'font-size': editor.getStyleRules('h5', 'font-size'),
				'line-height':
					getPropValue('h5', 'line-height') ||
					getPropValue('wrapper', 'line-height'),
				'font-family':
					getPropValue('h5', 'font-family') ||
					getPropValue('wrapper', 'font-family')
			},
			h6: {
				color:
					getPropValue('h6', 'color') ||
					getPropValue('wrapper', 'color'),
				'font-size': editor.getStyleRules('h6', 'font-size'),
				'line-height':
					getPropValue('h6', 'line-height') ||
					getPropValue('wrapper', 'line-height'),
				'font-family':
					getPropValue('h6', 'font-family') ||
					getPropValue('wrapper', 'font-family')
			},
			link: {
				color: getPropValue('link', 'color')
			}
		},
		toolbarOptions: {
			evalAllowExtraDropdown: dropdown =>
				!isButton || dropdown.type !== 'link',
			fontSizeValues: editor.getAvailableFontSizes(),
			lineHeightValues: editor.getAvailableLineHeights(),
			useLink: !isButton,
			useLineHeight: !isButton,
			lineHeightBySelection: !isButton,
			useFormatters: {
				code: false,
				paragraph: true,
				h1: true,
				h2: true,
				h3: true,
				h4: true,
				h5: true,
				h6: true,
				numberedList: false,
				bulletList: false
			}
		}
	};

	return merge(
		commonOptions,
		editor.getEmailBuilderOptions()?.textEditorOptions || {}
	);
};

export { textEditorCommonOptions, textEditorUpdateColorsOnGeneralChange };
