import cssInliner from '../_cssInliner';
import { merge } from 'datatalks-utils';
import exportCanvasConfiguration from './assets/common/functions/_exportConfig';
import emailTemplate from './_responsiveEmailTemplate';

export default (emailBuilder, options) => {
	const { editor } = emailBuilder;
	const defaults = {
		onlyGeneralStyles: false,
		storeFullDocument: emailBuilder.getOptions().html.exportFullDocument
	};

	options = merge(defaults, options);

	const template = editor.getHtml() + `<style>${editor.getCss()}</style>`;
	let html = cssInliner(template);
	if (options.storeFullDocument) {
		html = emailTemplate(html);
	}
	let css = editor.getStyle()?.models;
	const config = exportCanvasConfiguration(emailBuilder);

	if (options.onlyGeneralStyles)
		css = css.filter(rule => rule.get('selectorsAdd'));

	return emailBuilder.getOptions()?.storeStyles
		? { html, css, config }
		: { html, config };
};
