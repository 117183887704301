import { snakeCase, kebabCase } from 'lodash-es';

window['snakeCase'] = snakeCase;
window['kebabCase'] = kebabCase;

export default (emailBuilder, prop, innerProp) => {
	const { editor } = emailBuilder;
	if (!prop || prop === 'background') {
		editor.setStyleRules(
			'wrapper',
			{ 'background-color': emailBuilder.styles.background.email },
			{
				addStyles: true
			}
		);
	}

	if (!prop || prop === 'width') {
		editor.setStyleRules(
			'wrapper',
			{ width: emailBuilder.styles.width },
			{ addStyles: true }
		);
	}

	if (!prop || prop === 'fontColors') {
		editor.setStyleRules(
			'link',
			{ color: emailBuilder.styles.fontColors.link },
			{ addStyles: true }
		);
	}

	if (!prop || prop === 'links') {
		editor.setStyleRules(
			'link',
			{
				'text-decoration': emailBuilder.styles.links.underline
					? 'underline'
					: 'none'
			},
			{ addStyles: true }
		);
		editor.setStyleRules(
			'link',
			{
				'text-decoration-line': emailBuilder.styles.links.underline
					? 'underline'
					: 'none'
			},
			{ addStyles: true }
		);
		editor.setStyleRules(
			'link',
			{
				'text-decoration-color':
					emailBuilder.styles.links.underlineColor
			},
			{ addStyles: true }
		);
	}

	if (!prop || prop === 'fontSizes') {
		if (innerProp) {
			editor.setStyleRules(
				innerProp,
				{ 'font-size': emailBuilder.styles.fontSizes[innerProp] },
				{ addStyles: true }
			);
		} else {
			Object.keys(emailBuilder.styles.fontSizes).forEach(tag => {
				editor.setStyleRules(
					tag,
					{ 'font-size': emailBuilder.styles.fontSizes[tag] },
					{ addStyles: true }
				);
			});
		}
	}

	if (!prop || prop === 'fontColors') {
		if (innerProp) {
			editor.setStyleRules(
				innerProp,
				{ color: emailBuilder.styles.fontColors[innerProp] },
				{ addStyles: true }
			);
		} else {
			Object.keys(emailBuilder.styles.fontSizes).forEach(tag => {
				editor.setStyleRules(
					tag,
					{ color: emailBuilder.styles.fontColors[tag] },
					{ addStyles: true }
				);
			});
		}
	}

	if (!prop || prop === 'lineHeights') {
		if (innerProp) {
			editor.setStyleRules(
				innerProp,
				{ 'line-height': emailBuilder.styles.lineHeights[innerProp] },
				{ addStyles: true }
			);
		} else {
			Object.keys(emailBuilder.styles.lineHeights).forEach(tag => {
				editor.setStyleRules(
					tag,
					{ 'line-height': emailBuilder.styles.lineHeights[tag] },
					{ addStyles: true }
				);
			});
		}
	}

	if (!prop || prop === 'fonts') {
		if (innerProp) {
			editor.setStyleRules(
				innerProp,
				{ 'font-family': emailBuilder.getTagFontFamily(innerProp) },
				{ addStyles: true }
			);
		} else {
			Object.keys(emailBuilder.styles.fonts).forEach(tag => {
				if (emailBuilder.getTagFontFamily(tag))
					editor.setStyleRules(
						tag,
						{ 'font-family': emailBuilder.getTagFontFamily(tag) },
						{ addStyles: true }
					);
			});
		}
	}

	if (!prop || prop === 'buttons') {
		if (innerProp) {
			editor.setStyleRules(
				'button',
				{
					[kebabCase(innerProp)]:
						emailBuilder.styles.buttons[innerProp]
				},
				{ addStyles: true }
			);
		} else {
			editor.setStyleRules(
				'button',
				{ height: emailBuilder.styles.buttons.height },
				{ addStyles: true }
			);
			editor.setStyleRules(
				'button',
				{ 'line-height': emailBuilder.styles.buttons.lineHeight }, // What if auto?
				{ addStyles: true }
			);
			editor.setStyleRules(
				'button',
				{
					['background-color']:
						emailBuilder.styles.buttons.backgroundColor
				},
				{ addStyles: true }
			);
			editor.setStyleRules(
				'button',
				{ color: emailBuilder.styles.buttons.color },
				{ addStyles: true }
			);
			editor.setStyleRules(
				'button',
				{ 'font-family': emailBuilder.styles.buttons.fontFamily },
				{ addStyles: true }
			);
			['top', 'bottom', 'left', 'right'].forEach(side => {
				if (emailBuilder.getStyleProp('buttons').paddings[side])
					editor.setStyleRules(
						'button',
						{
							[`padding-${side}`]:
								emailBuilder.getStyleProp('buttons').paddings[
									side
								]
						},
						{ addStyles: true }
					);
			});
			Object.keys(emailBuilder.styles.buttons.borderRadius).forEach(
				corner => {
					editor.setStyleRules(
						'button',
						{
							[`border-${kebabCase(corner)}-radius`]:
								emailBuilder.styles.buttons.borderRadius[corner]
						},
						{ addStyles: true }
					);
				}
			);
		}
	}
};
