import {
	cssSidesToObject,
	cssCornersToObject,
	merge,
	arrayAvg,
	cssStyleObjectToString,
	htmlToElement,
	isElement
} from 'datatalks-utils';
import { validateStyleObj } from '../../../common/functions/_utilFunctions';
import getComputedStyle from './_getComputedStyle';

export default (comp, options) => {
	const defaults = {
		debug: false,
		style: getComputedStyle(comp),
		text: comp.get('text'),
		href: comp.get('href'),
		parentWidth: null
	};

	options = merge({}, defaults, options);

	if (!options.parentWidth)
		throw new Error(
			"Can't export HTML as component parent's width is required"
		);

	// CALCULATIONS
	const height = options.style['min-height'];
	const isBorderVisible = !(
		!options.style['border-width'] ||
		!options.style['border-color'] ||
		parseFloat(options.style['border-width']) < 0.1 ||
		options.style['border-color'] == 'transparent'
	);
	const paddingSide = cssSidesToObject(options.style.padding);
	const borderWidthSide = options.style['border-width']
		? parseFloat(options.style['border-width']) || 0
		: 0;
	const msoHeight =
		height == 'auto'
			? paddingSide.top +
			  2 * borderWidthSide +
			  parseFloat(options.style['font-size']) +
			  'px'
			: height;
	const borderRadiusCorner = cssCornersToObject(
		options.style['border-radius']
	);
	const borderRadiusRatio = options.style['border-radius']
		? height != 'auto'
			? (arrayAvg(Object.values(borderRadiusCorner)) * 100) /
			  parseFloat(height)
			: 50
		: 0;
	const msoInnerHeight =
		parseFloat(msoHeight) -
		((borderRadiusRatio <= 50 ? borderRadiusRatio : 50) *
			parseFloat(height)) /
			200 -
		(isBorderVisible ? borderWidthSide : 0) +
		'px';
	const buttonWidth =
		parseFloat(options.parentWidth) *
			(parseFloat(options.style.width) / 100) +
		'px';

	// TODO: improve this -> temporary fix
	options.msoText = options.text;
	if (validateStyleObj(options.style)['line-height']) {
		const textEl = htmlToElement(options.text);
		if (isElement(textEl)) {
			textEl.style.lineHeight = validateStyleObj(options.style)[
				'line-height'
			];
			options.text = textEl.outerHTML;
		}
	}

	const html = `
	<div ${options.debug ? 'data-eb-name="button-first"' : ''}>
		<!--[if mso]>
			<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml"
						xmlns:w="urn:schemas-microsoft-com:office:word"
						${options.href ? `href="${options.href}"` : ''}
						style="height:${msoHeight};
							v-text-anchor:middle;
							width: ${buttonWidth}"
						arcsize="${borderRadiusRatio}%"
						stroke=${isBorderVisible ? 't' : 'f'}
						${
							options.style['border-color']
								? `strokecolor="${options.style['border-color']}"`
								: ''
						}
						strokeweight="${options.style['border-width'] || 0}"
						fillcolor="${options.style['background-color']}">
				<w:anchorlock/>
				<v:textbox inset="0,0,0,0">
					<center>
						<table border="0" cellpadding="0" cellspacing="0" align="center" height="${msoInnerHeight}"  width="${buttonWidth}" style="width:${buttonWidth}; height:${msoInnerHeight};margin:0;">
							<tr style="height:${msoInnerHeight}" height="${msoInnerHeight}">
								<td width="${buttonWidth}" align="center" valign="middle" style="color:${
		options.style.color
	};font-family:${options.style['font-family']};font-size:${
		options.style['font-size']
	};font-weight:${options.style['font-weight']}; padding-left: ${
		paddingSide.left || 0
	}px; padding-right:${paddingSide.right || 0}px;">
									${options.msoText}
								</td>
							</tr>
						</table>
					</center>
				</v:textbox>
			</v:roundrect>
		<![endif]-->
		<!--[if !mso]><!-->
			${options.href ? `<a href="${options.href}">` : ''}
				<span ${options.debug ? 'data-eb-name="button-last"' : ''}
					class="ebr-button"
					style="${cssStyleObjectToString(validateStyleObj(options.style))}">
					${options.text.replace(
						// TODO: find a better way to handle this
						/&quot;/g,
						"'"
					)}
				</span>
			${options.href ? `</a>` : ''}
		<!--<![endif]-->
	</div>
	`;

	if (options.debug) console.log('List HTML: ', html);

	return html;
};
