import ComponentBuilder from './assets/_componentBuilder';
import theme from './assets/styles/baseTheme.css';
import { merge } from 'datatalks-utils';
import { validateStyleObj } from './assets/common/functions/_utilFunctions';

export default emailBuilder => {
	const { editor } = emailBuilder;

	// Component Builder
	editor.componentBuilder = cbOptions => {
		new ComponentBuilder(editor, cbOptions);
	};

	editor.updateUndoRedoButtons = () => {
		editor.Panels.getButton('navigate', 'undo').set(
			'disable',
			!editor.UndoManager.hasUndo()
		);
		editor.Panels.getButton('navigate', 'redo').set(
			'disable',
			!editor.UndoManager.hasRedo()
		);
	};

	// GET Email Builder
	editor.getEmailBuilder = () => {
		return emailBuilder;
	};

	// GET Email Builder Options
	editor.getEmailBuilderOptions = () => {
		return emailBuilder.getOptions();
	};

	// GET Email Builder Available Font Sizes
	editor.getAvailableFontSizes = () => {
		return emailBuilder.getAvailableFontSizes();
	};

	// GET Email Builder Available Line Heights
	editor.getAvailableLineHeights = () => {
		return emailBuilder.getAvailableLineHeights();
	};

	// GET Email Builder Traits Options
	editor.getTraitsOptions = () => {
		return emailBuilder.getTraitsOptions();
	};

	// GET Email Builder Commands Options
	editor.getCommandsOptions = () => {
		return emailBuilder.getCommandsOptions();
	};

	// ADD Email Builder Commands Options
	editor.addCommandsOptions = commandsOptions => {
		return emailBuilder.addOptions({
			config: { commands: commandsOptions }
		}).config.commands;
	};

	// GET Email Builder Single Command Options
	editor.getCommandOptions = command => {
		if (command) {
			return emailBuilder.getCommandsOptions()[command];
		} else {
			console.error('No command specified.');
		}
	};

	// GET Email Builder Components Options
	editor.getComponentsOptions = () => {
		return emailBuilder.getComponentsOptions();
	};

	// ADD Email Builder Components Options
	editor.addComponentsOptions = componentsOptions => {
		return emailBuilder.addOptions({
			config: { components: componentsOptions }
		}).config.components;
	};

	// GET Email Builder Single Component Options
	editor.getComponentOptions = component => {
		if (component) {
			return emailBuilder.getComponentsOptions()[component];
		} else {
			console.error('No component specified.');
		}
	};

	// ADD Email Builder Components Options
	editor.addComponentOptions = (component, componentOptions) => {
		if (!componentOptions) {
			console.warn('No component options specified.');
			return;
		}
		if (!component) {
			console.error('No component specified.');
			return;
		}
		return emailBuilder.addOptions({
			config: { components: { [component]: componentOptions } }
		}).config.components;
	};

	// GET/SET Base Theme
	editor.getBaseTheme = () => theme;

	editor.setBaseTheme = () => {
		editor.Css.addRules(theme);
	};

	editor.addCssVars = cssVars => {
		editor.Css.addRules(`:root { ${cssVars} }`);
	};

	editor.setStyleProperties = () => {
		if (emailBuilder.layout.useOuterBackground)
			editor.Canvas.getDocument().documentElement.style.setProperty(
				'--outer-background',
				emailBuilder.styles.background.outer
			);
	};

	// Delete Style Rules
	editor.removeStyleRules = (element, property) => {
		if (property) {
			const styleObj = editor.Css.getRule(
				editor.elementSelectors[element]
			).getStyle();
			delete styleObj[property];
			return editor.Css.setRule(
				editor.elementSelectors[element],
				styleObj
			);
		} else {
			const rule = editor.Css.getRule(editor.elementSelectors[element]);
			return editor.Css.remove(rule);
		}
	};

	// GET/SET Style Rules
	editor.getStyleRules = (element, property, options) => {
		const defaults = {
			inheritReturnsNull: true
		};
		options = merge(defaults, options);

		let rule = editor.Css.getRule(editor.elementSelectors[element]);
		rule = property ? rule?.attributes?.style[property] : rule;

		return rule === 'inherit' && options.inheritReturnsNull ? null : rule;
	};

	editor.setStyleRules = (element, styleObj, options = {}) => {
		styleObj = validateStyleObj(styleObj);
		return editor.Css.setRule(
			editor.elementSelectors[element],
			styleObj,
			options
		);
	};

	// SET Dynamic Links
	editor.setDynamicLinks = dynamicLinks => {
		emailBuilder.addOptions({ dynamicLinks });
		emailBuilder.updateTextEditorsDropdowns();
	};

	// SET Dynamic Values
	editor.setDynamicValues = dynamicValues => {
		emailBuilder.addOptions({ dynamicValues });
		emailBuilder.updateTextEditorsDropdowns();
	};

	// Deselect All Components
	editor.deselectAll = () => {
		if (editor.getSelectedAll().length)
			editor.getSelectedAll().forEach(selectedComp => {
				editor.selectRemove(selectedComp);
			});
	};

	// Change Context
	editor.changeContext = ({ label, goBack, callback }) => {
		if (typeof goBack === 'function') {
			editor.Panels.getButton('context', 'context-go-back-icon').set(
				'className',
				'context__icon'
			); // TODO: add this dynamically
			editor.Commands.extend('change-context', {
				run(editor, sender) {
					goBack();
				}
			});
		} else {
			editor.Panels.getButton('context', 'context-go-back-icon').set(
				'className',
				'context__icon context__icon--hidden'
			); // TODO: add this dynamically
		}
		editor.Panels.getButton('context', 'context-title').set('label', label);
		if (typeof callback === 'function') callback();
	};

	// Wait for Assets
	editor.waitForAssets = listener => {
		editor.runCommand('open-assets');
		editor.on('run:asset-set', listener);
		editor.on('run:open-assets', (opts, sender) => {
			editor.off('run:asset-set', listener);
		});
	};

	// Development Mode
	editor.setDevMode = (devMode = true) => {
		emailBuilder.addOptions({ devMode });
		if (editor?.getWrapper()?.getEl())
			editor.getWrapper().getEl().setAttribute('data-dev-mode', devMode);
	};
};
