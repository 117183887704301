/* eslint-disable require-jsdoc */
import style from './editor.css';
import {
	createEditor,
	$getRoot,
	LineBreakNode,
	ParagraphNode,
	TextNode,
	$insertNodes,
	$getSelection,
	$isRangeSelection,
	SELECT_ALL_COMMAND,
	FOCUS_COMMAND,
	BLUR_COMMAND,
	COMMAND_PRIORITY_LOW,
	FORMAT_TEXT_COMMAND
} from 'lexical';
import { $patchStyleText } from '@lexical/selection';
import { LinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { registerRichText, HeadingNode } from '@lexical/rich-text';
import { CodeNode } from '@lexical/code';
import { ListNode, ListItemNode } from '@lexical/list';
import listPlugin from './_listPlugin';
import linkPlugin from './_linkPlugin';
import theme from './_theme';
import { themeRoot } from './_theme';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import onChangePlugin from './_onChangePlugin';
import onSelectionChangePlugin from './_onSelectionChangePlugin';
import { merge } from 'lodash-es';
import TextEditorToolbar from './_TextEditorToolbar';
import { addClassesString, isValidColor, arrayAvg } from 'datatalks-utils';
import { getTextColorsFromEditorState } from './_utils';
import { getLuminance } from 'color2k';

const availableNodes = {
	LineBreakNode,
	ParagraphNode,
	TextNode,
	HeadingNode,
	CodeNode,
	ListNode,
	ListItemNode,
	LinkNode
};

export default class TextEditor {
	constructor(options = {}) {
		this.defaults = {
			namespace: 'EmailBuilderTextEditor',
			theme: theme('eb-editor'),
			onError: console.error,
			useNodes: {
				LineBreakNode: true,
				ParagraphNode: true,
				TextNode: true,
				HeadingNode: true,
				CodeNode: true,
				ListNode: true,
				ListItemNode: true,
				LinkNode: true
			},
			onChange: null,
			onLoad: null,
			element: null,
			plugins: [listPlugin, registerRichText, linkPlugin],
			placeholder: 'Type here...',
			initialContent: '',
			initialState: null,
			useIframe: true,
			useToolbar: true,
			toolbarOptions: {
				className: `${this.className}-toolbar`
			},
			classPrefix: 'eb-',
			className: 'prosetyper',
			iframeStyle: style,
			iframeStyleOptions: {},
			selectAllTextOnFocus: true,
			changeableHeadingsWeight: true,
			startBold: false,
			editorBackground: '#ffffff',
			autoEditorBackground: true
		};

		this.defaults.iframeStyleRoot = themeRoot(
			merge(
				this.defaults.iframeStyleOptions,
				options.iframeStyleOptions || {}
			)
		);

		this.defaults.toolbarOptions.className = `${
			options.className || this.defaults.className
		}-toolbar`;

		this.options = merge(this.defaults, options);

		this.nodes = [];
		Object.keys(this.options.useNodes).forEach(nodeName => {
			if (this.options.useNodes[nodeName]) {
				this.nodes.push(availableNodes[nodeName]);
			}
		});

		this.prefixedClassName = `${this.options.classPrefix}${this.options.className}`;
		this.className = this.options.className;
		this.editorBackground = this.options.editorBackground || '#ffffff';
		this.wrapper = this.options.element || document.createElement('div');
		this.wrapper.className = this.prefixedClassName;
		this.editor = null;
		this.config = null;
		this.onChange = this.options.onChange;
		this.parser = new DOMParser();
		this.isShowingPlaceholder =
			!this.options.initialContent &&
			!this.options.initialState &&
			this.options.placeholder;
		this.editorHasFocus = false;

		this.init();
	}

	isLinkNode(node) {
		return $isLinkNode(node);
	}

	isRangeSelection(selection) {
		return $isRangeSelection(selection);
	}

	changeFont(font) {
		if (font)
			this.editor.update(() => {
				const selection = $getSelection();
				if (!$isRangeSelection(selection)) {
					return false;
				}
				$patchStyleText(selection, {
					'font-family': font
				});
				return true;
			});
	}

	init() {
		const textEditor = this;
		textEditor.config = {
			namespace: textEditor.options.namespace,
			theme: textEditor.options.theme,
			onError: textEditor.options.onError,
			nodes: textEditor.nodes
		};

		textEditor.editor = createEditor(textEditor.config);

		textEditor.editor.registerCommand(
			FOCUS_COMMAND,
			() => {
				textEditor.editorHasFocus = true;
			},
			COMMAND_PRIORITY_LOW
		);

		textEditor.editor.registerCommand(
			BLUR_COMMAND,
			() => {
				textEditor.editorHasFocus = false;
			},
			COMMAND_PRIORITY_LOW
		);

		if (textEditor.options.selectAllTextOnFocus) textEditor.setOnFocus();
		textEditor.setOnChange();
		textEditor.setRootElement();
		textEditor.registerPlugins();

		if (
			textEditor.options.initialContent ||
			textEditor.options.initialState
		) {
			textEditor.setInitialContent();
		} else {
			textEditor.showPlaceholder();
		}

		textEditor.stateColors = textEditor.getStateColors();
		if (textEditor.stateColors?.length) {
			this.options.toolbarOptions.defaultColor = [
				...textEditor.stateColors
			].pop();
		}

		if (textEditor.options.useToolbar) {
			textEditor.createToolbar();

			onSelectionChangePlugin(textEditor.editor, () => {
				if (textEditor.options.useToolbar) {
					textEditor.editor.getEditorState().read(() => {
						textEditor.toolbar.eventEmitter.emit(
							'selectionChange',
							$getSelection()
						);
					});
				}
			});

			if (textEditor.options.autoEditorBackground) {
				textEditor.toolbar.on('change:color', color => {
					textEditor.evaluateEditorBackground();
				});
			}
		}

		if (textEditor.options.startBold) {
			textEditor.setAllBold();
		}

		textEditor.draw();
	}

	evaluateEditorBackground() {
		// TODO: change to have configurable colors and threshold
		const colors = this.getStateColors();
		if (colors?.length) {
			const luminances = colors
				.map(color =>
					isValidColor(color) ? getLuminance(color) : null
				)
				.filter(l => l !== null);
			if (luminances.filter(l => l > 0.8).length) {
				this.setEditorBackground('#3d3d3d');
			} else if (luminances.filter(l => l < 0.2).length) {
				this.setEditorBackground('#ffffff');
			} else if (arrayAvg(luminances) > 0.5) {
				this.setEditorBackground('#3d3d3d');
			} else {
				this.setEditorBackground('#ffffff');
			}
		}
	}

	getStateColors(state = this.editor.getEditorState()) {
		return getTextColorsFromEditorState(this.editor.getEditorState());
	}

	getSelectionColor(selection) {
		let color;
		this.editor.read(() => {
			selection = selection || $getSelection();
			if ($isRangeSelection(selection)) {
				const el = this.editor.getElementByKey(
					selection.anchor.getNode().getKey()
				);
				console.log(el);
				if (el && getComputedStyle(el)?.color) {
					color = getComputedStyle(el).color;
				}
			}
		});
		return color;
	}

	setAllBold() {
		const textEditor = this;
		textEditor.editor.dispatchCommand(SELECT_ALL_COMMAND);
		textEditor.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
	}

	showPlaceholder() {
		this.isShowingPlaceholder = true;
	}

	setInitialContent() {
		const textEditor = this;
		textEditor.editor.update(() => {
			if (textEditor.options.initialState) {
				if (textEditor.options.initialState.hasOwnProperty('root')) {
					textEditor.options.initialState =
						textEditor.editor.parseEditorState(
							textEditor.options.initialState
						);
				}

				textEditor.editor.setEditorState(
					textEditor.options.initialState
				);
			} else {
				textEditor.setHtml(textEditor.options.initialContent);
			}
			textEditor.inlineTextAlignment();
		});
		textEditor.isShowingPlaceholder = false;
	}

	inlineTextAlignment() {
		this.editor.update(() => {
			$getRoot()
				.getChildren()
				.forEach(e => {
					if (!e.getFormat()) e.setFormat('left');
				});
		});
	}

	setHtml(htmlString) {
		const dom = this.parser.parseFromString(htmlString, 'text/html');
		$getRoot().select();
		$insertNodes($generateNodesFromDOM(this.editor, dom));
	}

	registerPlugins() {
		this.options.plugins.forEach(plugin => {
			plugin(this.editor);
		});
	}

	setEditorBackground(color) {
		if (isValidColor(color)) {
			this.editorBackground = color;
			// TODO: change to use CSS variables with default colors
			this.frame.style.backgroundColor = this.editorBackground;
		} else {
			console.warn(
				'Trying to set an invalid background color on editor.',
				this,
				color
			);
		}
	}

	setRootElement() {
		const textEditor = this;
		if (true) {
			// TODO: textEditor.options.useIframe
			textEditor.frame = document.createElement('iframe');
			textEditor.frame.className = `${textEditor.prefixedClassName}-frame`;
			const blob = new Blob(
				[
					`
				<!DOCTYPE html>
				<html>
				<head>
				</head>
				<body>
				</body>
				</html>
			`
				],
				{ type: 'text/html' }
			);
			textEditor.frame.src = URL.createObjectURL(blob);

			textEditor.frame.onload = () => {
				// create iframe style
				const style = document.createElement('style');
				const options = textEditor.getOptions();
				style.innerHTML = `${options.iframeStyle}\n${options.iframeStyleRoot}`;

				// append iframe style to iframe head
				if (textEditor.frame.contentDocument)
					textEditor.frame.contentDocument.head.appendChild(style);

				textEditor.frame.contentDocument.body.addEventListener(
					'click',
					e => {
						window.parent.postMessage('click', '*');
					}
				);

				// create iframe editor
				textEditor.editorEl =
					textEditor.frame.contentDocument.createElement('div');
				textEditor.editorEl.contentEditable = true;
				if (textEditor.options.autoEditorBackground) {
					textEditor.evaluateEditorBackground();
				} else {
					textEditor.setEditorBackground(textEditor.editorBackground);
				}
				textEditor.editorEl.className = `${textEditor.prefixedClassName}-editor`;
				if (textEditor.options.changeableHeadingsWeight)
					textEditor.editorEl.classList.add(
						`${textEditor.prefixedClassName}-editor--changeable-headings-weight`
					);
				textEditor.editor.setRootElement(textEditor.editorEl);

				// append iframe editor to iframe body
				textEditor.frame.contentDocument.body.append(
					textEditor.editorEl
				);

				if (typeof options.onLoad === 'function') {
					textEditor.editor.update(() => {
						textEditor.html = $generateHtmlFromNodes(
							textEditor.editor
						);
						options.onLoad(
							textEditor,
							textEditor.editor.getEditorState(),
							textEditor.html
						);
					});
				}
			};
		}
	}

	getOptions() {
		return this.options;
	}

	setOptions(obj, override = false) {
		this.options = !override ? merge(this.options, obj) : obj;
		if (obj.hasOwnProperty('iframeStyleOptions')) {
			this.options.iframeStyleRoot = themeRoot(
				this.options.iframeStyleOptions
			);
		}
	}

	draw() {
		if (this.options.useToolbar) {
			this.wrapper.appendChild(this.toolbar.getEl());
			this.wrapper.appendChild(
				addClassesString(
					document.createElement('div'),
					`${this.prefixedClassName}-separator`
				)
			);
		}
		this.wrapper.appendChild(this.frame);
	}

	setOnChange() {
		onChangePlugin(this.editor, {
			onChange: this.handleOnChange.bind(this),
			ignoreSelectionChange: true
		});
	}

	setOnFocus() {
		const textEditor = this;
		textEditor.editor.registerCommand(
			FOCUS_COMMAND,
			() => {
				if (!$getSelection())
					textEditor.editor.dispatchCommand(SELECT_ALL_COMMAND);
			},
			COMMAND_PRIORITY_LOW
		);
	}

	handleOnChange(editorState, editor) {
		const textEditor = this;
		editor.update(() => {
			textEditor.html = $generateHtmlFromNodes(editor);
			if (typeof this.onChange === 'function') {
				this.onChange(textEditor.html, editorState, editor);
			}
		});
	}

	getHtml() {
		return this.html;
	}

	createToolbar() {
		this.toolbar = new TextEditorToolbar(
			merge(
				{ editor: this.editor, textEditor: this },
				this.options.toolbarOptions
			)
		);
	}

	addLink(href) {
		this.editor.dispatchCommand(TOGGLE_LINK_COMMAND, href);
	}

	getEl() {
		return this.wrapper;
	}

	destroy() {
		if (this.toolbar && typeof this.toolbar.destroy === 'function') {
			this.toolbar.destroy();
		}
	}
}
