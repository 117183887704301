import { merge } from 'datatalks-utils';
import { RadioButtonGroup } from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';

export default (obj, options) => {
	const defaults = {
		extendedClasses: '',
		itemsOptions: {
			useActiveBorder: false,
			extendedClasses: 'eb-py-2'
		},
		icons: {
			alignLeft: getIcon('align-left', { size: 'xl' }),
			alignCenter: getIcon('align-center', { size: 'xl' }),
			alignRight: getIcon('align-right', { size: 'xl' })
		},
		componentProperty: 'buttonAlignment'
	};

	options = merge(defaults, options);

	const radioButtonGroupOptions = {
		onChange: (allSelected, changedItem, selectedItems, noneSelected) => {
			obj.component.setAndRerender(
				options.componentProperty,
				changedItem.getValue()
			);
		},
		singleSelection: true,
		allowNoSelection: false,
		items: [
			{
				value: 'left',
				selected:
					obj.component.get(options.componentProperty) === 'left',
				icon: options.icons.alignLeft,
				...options.itemsOptions
			},
			{
				value: 'center',
				selected:
					obj.component.get(options.componentProperty) === 'center',
				icon: options.icons.alignCenter,
				...options.itemsOptions
			},
			{
				value: 'right',
				selected:
					obj.component.get(options.componentProperty) === 'right',
				icon: options.icons.alignRight,
				...options.itemsOptions
			}
		]
	};

	const marginsButtonGroup = new RadioButtonGroup(radioButtonGroupOptions);

	return marginsButtonGroup;
};
