import { merge } from 'datatalks-utils';
import { validateStyleObj } from '../../common/functions/_utilFunctions';
import { cssStyleObjectToString } from 'datatalks-utils';

export default (model, opts) => {
	const defaults = {
		debug: false,

		tag: null,
		attributes: null,
		altQuoteAttr: null,
		withProps: null,
		replaceWrapperByTable: false,
		copyWrapperAttributes: true,

		preHeader: '',
		ignoreEmptyPreHeader: false,

		width: null,
		backgroundColor: null,
		useOuterBackground: false,
		outerBackground: null
	};

	opts = merge(defaults, opts);

	/**
	 * Returns the HTML representation of the inner components.
	 * @return {string} The HTML representation of the inner components.
	 */
	function getInner() {
		const children = model.components();
		return children.length
			? children.map(c => c.toHTML({ parentWidth: opts.width })).join('')
			: model.content;
	}

	const preHeader =
		opts.ignoreEmptyPreHeader && !opts.preHeader
			? ''
			: `
		<span style="
			line-height: 0;
			visibility: hidden;
			opacity: 0;
			height: 0;
			width: 0;
			font-size: 0px;
			display: none;"
		>
			${opts.preHeader || ''}
		</span>
	`;

	const debugStrStart = opts.debug ? 'data-eb-name="wrapper-first"' : '';
	const debugStrEnd = opts.debug ? 'data-eb-name="wrapper-last"' : '';

	const toStyleString = stylesObj =>
		cssStyleObjectToString(validateStyleObj(stylesObj));

	const html = `
		<body ${debugStrStart} style="margin:0;padding:0;word-spacing:normal;background-color:#ffffff;">
			${preHeader}
			<div role="article" aria-roledescription="email" lang="en"
				style="-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;${toStyleString({
					'background-color': opts.useOuterBackground
						? opts.outerBackground
						: null
				})};">
				<table role="presentation" style="width:100%;border:0;border-spacing:0;${toStyleString(
					{
						'background-color': opts.useOuterBackground
							? opts.outerBackground
							: null
					}
				)}" ${
		opts.useOuterBackground ? `bgcolor="${opts.outerBackground}"` : ''
	}>
					<tr>
						<td align="center">
							<!--[if mso]>
								<table data-gjs-type="wrapper" role="presentation" align="center" style="${toStyleString(
									{
										'background-color':
											opts.backgroundColor,
										width: opts.width
									}
								)}" width="${opts.width}" >
									<tr>
										<td style="padding:0 0;">
											<![endif]-->
												<div ${debugStrEnd} class="outer" style="${toStyleString({
		'background-color': opts.backgroundColor,
		'max-width': opts.width
	})};margin:0 auto;">
													${getInner()}
												</div>
											<!--[if mso]>
										</td>
									</tr>
								</table>
							<![endif]-->
						</td>
					</tr>
				</table>
			</div>
		</body>
	`;

	return html;
};
