import { $getRoot, $getSelection } from 'lexical';
import { parseStyleString } from 'datatalks-utils';

/**
 * Retrieves the text colors from the provided editor state.
 *
 * @param {EditorState} editorState - The editor state object.
 * @return {String[] | null} The text colors array or null if no text color is found.
 */
function getTextColorsFromEditorState(editorState) {
	const textColors = [];

	editorState.read(() => {
		const root = $getRoot();
		const selection = $getSelection();
		if (selection || root) {
			const nodes = selection ? selection.getNodes() : getAllNodes(root);

			if (nodes.length) {
				nodes.forEach(node => {
					if (node.getStyle) {
						let style = node.getStyle();
						if (style) {
							if (typeof style === 'string') {
								style = parseStyleString(style);
							}
							if (style.color) {
								textColors.push(style.color);
							}
						}
					}
				});
			}
		}
	});

	return textColors.length ? textColors : null;
}

/**
 * Recursively retrieves all nodes from the root node.
 *
 * @param {LexicalNode} node - The root node.
 * @return {Array<LexicalNode>} An array of all nodes.
 */
function getAllNodes(node) {
	let nodes = [node];
	if (node.getChildren) {
		node.getChildren().forEach(child => {
			nodes = nodes.concat(getAllNodes(child));
		});
	}
	return nodes;
}

export { getTextColorsFromEditorState, getAllNodes };
