import { merge } from 'datatalks-utils';
import cssInliner from '../../../../_cssInliner';
import emailTemplate from '../../../_responsiveEmailTemplate';

export default (emailBuilder, options) => {
	const { editor } = emailBuilder;
	const defaults = {
		useWrapper: false,
		exportFullDocument: emailBuilder.getOptions().html.exportFullDocument
	};

	options = merge(defaults, options);
	let html;
	if (options.useWrapper) {
		const wrapperClone = editor.getWrapper().getEl().cloneNode();
		wrapperClone.innerHTML = editor.getHtml();

		html = cssInliner(
			wrapperClone.outerHTML + `<style>${editor.getCss()}</style>`
		);
	} else {
		html = cssInliner(
			editor.getHtml() + `<style>${editor.getCss()}</style>`
		);
	}

	if (options.exportFullDocument) {
		html = emailTemplate(html);
	}

	return html;
};
