/* eslint-disable require-jsdoc */
import addComponents from './assets/components/_components';
import loadBlocks from './assets/blocks/_blocks';
import addTraits from './assets/traits/_traits';
// import addPanels from './assets/panels/_panels';
import addCommands from './assets/commands/_commands';
import en from './locale/en';
import setPanels from './_panelConfig';
import setCustomMethods from './_customMethods';
import setCustomProps from './_customProps';
import addEventListeners from './_eventListeners';
import addScopeManager from './ScopeManager/_scopeManager';
import {
	merge,
	mergeWith,
	removeSingleQuotes,
	EventEmitter
} from 'datatalks-utils';
import updateTextEditorsDropdowns from './_addDynamicLinks';
import getImagesInfo from './assets/common/functions/_getImagesInfo';
import isImagePlaceholder from './assets/common/functions/_isImagePlaceholder';
import getDataToStore from './_getDataToStore';
import applyGeneralStyles from './_applyGeneralStyles';
import getLegacyStyles from './_getLegacyStyles';

class EmailBuilder {
	constructor(editor, opts) {
		this.defaults = {
			storeStyles: false,
			titleConfig: {
				title: null,
				changeableTitle: true,
				titleOnChange: null
			},
			actionsLabels: {
				exportAll: 'Export All',
				exportHtml: 'Export HTML',
				exportConfig: 'Export Configuration',
				previewEmail: 'Preview Email',
				storageClear: 'Clear storage',
				downloadTemplate: 'Download template'
			},
			panelsLabels: {
				generalContext: 'Edit Email'
			},
			preventDefaultActions: {
				save: false
			},
			autoLoad: true,
			onExport: null,
			onExportHtml: null,
			onExportConfig: null,
			onDownloadTemplate: null,
			onPreviewEmail: null,
			onSave: null,
			onStorageClear: null,
			onAssetsOpen: null,
			onAssetsOpenOverridesDefault: true,
			devMode: false,
			config: {
				components: {},
				commands: {},
				traits: {},
				panels: {},
				blocks: {},
				layout: {
					useOuterBackground: false
				},
				text: {
					availableFontSizes: [
						'8px',
						'10px',
						'12px',
						'14px',
						'16px',
						'18px',
						'20px',
						'22px',
						'24px',
						'26px',
						'28px',
						'30px',
						'32px',
						'34px',
						'36px',
						'40px',
						'48px',
						'64px',
						'72px',
						'96px'
					],
					availableLineHeights: [
						0.71, 1, 1.15, 1.2, 1.3, 1.4, 1.5, 1.75, 2, 2.5, 3, 4, 5
					],
					lineHeightTypes: {
						auto: 'Auto',
						sameAsHeight: 'Same as height'
					}
				},
				styles: {
					width: '600px',
					background: {
						outer: '#f2f2f2',
						email: 'white',
						components: 'transparent'
					},
					borderRadius: {
						topLeft: '0px',
						topRight: '0px',
						bottomRight: '0px',
						bottomLeft: '0px'
					},
					useBorderRadius: false,
					borderWidth: '0px',
					borderColor: 'transparent',
					borderStyle: 'none',
					fonts: {
						wrapper: {
							font: 'Arial',
							fallback: 'sans-serif'
						},
						h1: {
							font: null,
							fallback: null
						},
						h2: {
							font: null,
							fallback: null
						},
						h3: {
							font: null,
							fallback: null
						},
						h4: {
							font: null,
							fallback: null
						},
						h5: {
							font: null,
							fallback: null
						},
						h6: {
							font: null,
							fallback: null
						},
						paragraph: {
							font: null,
							fallback: null
						}
					},
					fontSizes: {
						wrapper: '14px',
						h1: '28px',
						h2: '20px',
						h3: '16px',
						h4: '14px',
						h5: '12px',
						h6: '10px',
						paragraph: '14px'
					},
					fontColors: {
						wrapper: '#000000',
						h1: null,
						h2: null,
						h3: null,
						h4: null,
						h5: null,
						h6: null,
						paragraph: null,
						link: '#456cc0'
					},
					lineHeights: {
						wrapper: 1.2,
						h1: null,
						h2: null,
						h3: null,
						h4: null,
						h5: null,
						h6: null,
						paragraph: null
					},
					buttons: {
						backgroundColor: '#456cc0',
						color: '#ffffff',
						lineHeight: 'Auto',
						paddings: {
							top: '0px',
							right: '18px',
							bottom: '0px',
							left: '18px'
						},
						height: '40px',
						borderWidths: {
							top: '0px',
							right: '0px',
							bottom: '0px',
							left: '0px'
						},
						borderStyle: 'solid',
						borderColor: 'transparent',
						borderRadius: {
							topLeft: '0px',
							topRight: '0px',
							bottomRight: '0px',
							bottomLeft: '0px'
						},
						fontFamily: null,
						fallbackFontFamily: null
					},
					links: {
						underline: false,
						underlineColor: '#456cc0'
					}
				},
				advanced: {
					isMobileOptimized: true
				}
			},
			html: {
				usePreHeader: true,
				preHeaderText: '',
				exportFullDocument: true,
				transform: null
			},
			elementSelectors: {
				button: '.button',
				wrapper: "[data-gjs-type='wrapper']",
				link: 'a',
				h1: 'h1',
				h2: 'h2',
				h3: 'h3',
				h4: 'h4',
				h5: 'h5',
				h6: 'h6',
				paragraph: 'p',
				outer: 'body',
				component: "[draggable='true']"
			},
			dynamicLinks: [],
			dynamicValues: [],
			textEditorOptions: {},
			commandsId: {
				assetSet: 'asset-set',
				changeContext: 'change-context',
				clearStorage: 'clear-storage',
				downloadTemplate: 'download-template',
				exportAll: 'export-all',
				exportHtml: 'export-html',
				openContentTraits: 'open-content-traits',
				openGeneral: 'open-general',
				openStyleTraits: 'open-style-traits',
				openTraitManager: 'open-tm',
				showMainViewPanel: 'show-main-view-panel',
				showSavePanel: 'show-save-panel',
				showTraitsViewPanel: 'show-traits-view-panel',
				importConfig: 'import-config',
				exportConfig: 'export-config',
				checkDiffStorage: 'check-diff-storage',
				goBack: 'go-back',
				previewEmail: 'preview-email',
				changeGeneralStyleProp: 'change-general-style-prop',
				undo: 'undo',
				redo: 'redo',
				copy: 'core:copy',
				paste: 'core:paste',
				htmlPreview: 'html-preview'
			},
			renderingDebounceTime: 300,
			isImagePlaceholder
		};

		// Properties
		this.editor = editor;
		this.options = merge(this.defaults, opts);
		this.plugin = plugin;
		this.storedData = null;
		this.version = process.env.EMAIL_BUILDER_VERSION;
		this.eventEmitter = new EventEmitter();

		this.styles = this.options.config.styles;
		this.defaultStyles = this.defaults.config.styles;
		this.layout = this.options.config.layout;

		this.init();

		loadBlocks(editor, this.options);
		addScopeManager(editor, this.options);
		editor.I18n &&
			editor.I18n.addMessages({
				en,
				...this.options.i18n
			});

		this.onAssetsOpen();
	}

	init() {
		this.addEventListeners();
		this.setCustomProps();
		this.setCustomMethods();
		this.updateTextEditorsDropdowns();
		this.addTraits();
		this.addComponents();
		this.addCommands();
		this.setPanels();
	}

	addOptions(opts, methodOptions = {}) {
		const defaults = {
			mergeArrays: false
		};
		const { mergeArrays } = merge(defaults, methodOptions);
		this.options = mergeWith(this.options, opts, (objValue, srcValue) => {
			if (Array.isArray(objValue)) {
				if (mergeArrays) {
					return objValue.concat(srcValue);
				} else {
					return srcValue;
				}
			}
		});
		return this.options;
	}

	addStyleOptions(opts) {
		if (opts.fontSizes) {
			for (var prop in opts.fontSizes) {
				if (opts.fontSizes[prop] == null) {
					delete opts.fontSizes[prop];
				}
			}
		}
		this.styles = merge({}, this.styles, opts);
		return this.styles;
	}

	addLayoutOptions(opts) {
		this.layout = merge(this.layout, opts);
		return this.layout;
	}

	setOptions(opts) {
		this.options = opts;
	}

	getOptions() {
		return this.options;
	}

	getAvailableFontSizes() {
		return this.options?.config?.text?.availableFontSizes;
	}

	getAvailableLineHeights() {
		return this.options?.config?.text?.availableLineHeights;
	}

	getLineHeightTypes() {
		return this.options?.config?.text?.lineHeightTypes;
	}

	getTraitsOptions() {
		return this.options?.config?.traits;
	}

	getCommandsOptions() {
		return this.options?.config?.commands;
	}

	getComponentsOptions() {
		return this.options?.config?.components;
	}

	addEventListeners() {
		addEventListeners(this);
	}

	setCustomProps() {
		setCustomProps(this);
	}

	setCustomMethods() {
		setCustomMethods(this);
	}

	updateTextEditorsDropdowns() {
		updateTextEditorsDropdowns(this);
	}

	addTraits() {
		addTraits(this);
	}

	getCommandId(command) {
		return this.options.commandsId[command];
	}

	addComponents() {
		addComponents(this);
	}

	addCommands() {
		addCommands(this);
	}

	setPanels() {
		setPanels(this);
	}

	getImages(options) {
		return getImagesInfo(this, options);
	}

	isImagePlaceholder(img) {
		return this.options.isImagePlaceholder(img);
	}

	store() {
		this.editor.StorageManager.store(this.getDataToStore());
	}

	setStoredData(data) {
		this.storedData = data;
	}

	getStoredData() {
		return this.storedData;
	}

	getDataToStore() {
		return getDataToStore(this);
	}

	onAssetsOpen() {
		const eb = this;
		if (typeof eb.options.onAssetsOpen === 'function') {
			if (eb.options.onAssetsOpenOverridesDefault) {
				eb.editor.Commands.add('open-assets', {
					run(editor, sender, opts) {
						eb.options.onAssetsOpen(editor, sender, opts);
					}
				});
			} else {
				eb.editor.on('run:open-assets', (editor, sender, opts) => {
					eb.options.onAssetsOpen(editor, sender, opts);
				});
			}
		}
	}

	updateUndoRedoButtons() {
		this.editor.updateUndoRedoButtons();
	}

	setUseOuterBackground(useOuterBackground) {
		this.layout.useOuterBackground = useOuterBackground;
		if (!useOuterBackground) {
			this.editor.setStyleRules(
				'outer',
				{
					'background-color': '#7e8299'
				},
				{ addStyles: true }
			);
		} else {
			this.setOuterBackground();
		}
	}

	setOuterBackground(color = this.styles.background.outer) {
		this.editor.setStyleRules(
			'outer',
			{
				'background-color': color
			},
			{ addStyles: true }
		);
		this.styles.background.outer = color;
	}

	setStyleProp(prop, value) {
		return (this.styles[prop] = value);
	}

	getStyleProp(prop) {
		return this.styles[prop];
	}

	applyGeneralStyles(prop, innerProp) {
		applyGeneralStyles(this, prop, innerProp);
	}

	getTagFontFamily(tag, useInheritance = false) {
		let font;
		let fallback;

		if (useInheritance) {
			font =
				this.styles.fonts[tag].font ||
				this.styles.fonts['wrapper'].font;
			fallback = this.styles.fonts[tag].font
				? this.styles.fonts[tag].fallback ||
				  this.styles.fonts['wrapper'].fallback
				: this.styles.fonts['wrapper'].fallback;
		} else {
			font = this.styles.fonts[tag].font;
			fallback = this.styles.fonts[tag].fallback;
		}

		return [font, fallback].filter(f => !!f).join(', ');
	}

	getFontsFromCssValue(fontFamily) {
		if (fontFamily) {
			const fonts = fontFamily.split(', ') || fontFamily.split(',');
			return {
				font: fonts.length
					? removeSingleQuotes(fonts[0] || '') || null
					: null,
				fallback: fonts.length
					? removeSingleQuotes(fonts[1] || '') || null
					: null
			};
		} else {
			console.warn('No font family specified');
			return {
				font: null,
				fallback: null
			};
		}
	}

	/**
	 * Calculates the line height based on the provided value.
	 *
	 * @param {string} value - The value to calculate the line height from.
	 * @return {string} - The calculated line height.
	 */
	getButtonsAutoLineHeight() {
		const padding = this.getStyleProp('buttons', 'paddings');
		const borderWidth = this.getStyleProp('buttons', 'borderWidths');

		return (
			parseFloat(this.getStyleProp('buttons', 'height')) -
			parseFloat(padding.top) -
			parseFloat(padding.bottom) -
			parseFloat(borderWidth.top) -
			parseFloat(borderWidth.bottom) +
			'px'
		);
	}

	// TODO: delete this after every template has version >= 2.3.0
	getLegacyStyles() {
		getLegacyStyles(this);
	}

	getAdvancedOption(optionName) {
		return this.options.config.advanced[optionName];
	}

	setAdvancedOption(optionName, value) {
		return (this.options.config.advanced[optionName] = value);
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}

const plugin = (editor, opts = {}) => {
	new EmailBuilder(editor, opts);
};

export default plugin;

window['emailBuilderV2Plugin'] = plugin;
