/* eslint-disable require-jsdoc */
import {
	setContent,
	addClassesString,
	EventEmitter,
	merge
} from 'datatalks-utils';
import { getIcon } from 'datatalks-icons';
import { Button, Input } from 'datatalks-ui';

export default class EditableField {
	constructor(options = {}) {
		const defaults = {
			value: '',
			classPrefix: 'eb-',
			className: 'editable-field',
			extendedClasses: '',
			icons: {
				edit: null,
				save: null,
				discard: null
			},
			iconsSize: 'md',
			onEdit: null,
			onSave: null,
			onDiscard: null,
			onInputChange: null,
			startEditing: false,
			showEditButton: false
		};
		defaults.labelClickOpensEditMode = !options.showEditButton;
		defaults.icons = {
			save: getIcon('save-line', {
				size: options.iconsSize || defaults.iconsSize
			}),
			discard: getIcon('delete-bin-line', {
				size: options.iconsSize || defaults.iconsSize
			}),
			edit: getIcon('pencil-fill', {
				size: options.iconsSize || defaults.iconsSize
			})
		};

		this.options = merge(defaults, options);
		this.cssClass = `${this.options.classPrefix}${this.options.className}`;
		this.editMode = this.options.startEditing;
		this.value = this.options.value;
		this.eventEmitter = new EventEmitter();
		this.init();
	}
	init() {
		this.createEditModeContent();
		this.createViewModeContent();
		this.createEditModeButtons();
		if (this.options.showEditButton) this.createViewModeButtons();
		this.draw();
	}

	draw() {
		this.element = document.createElement('div');
		this.element.className = this.cssClass;
		if (this.options.extendedClasses)
			addClassesString(this.element, this.options.extendedClasses);
		this.contentWrapper = document.createElement('div');
		this.contentWrapper.className = `${this.cssClass}__content`;
		this.buttonsWrapper = document.createElement('div');
		this.buttonsWrapper.className = `${this.cssClass}__buttons`;
		setContent(this.element, [this.contentWrapper, this.buttonsWrapper]);
		if (this.editMode) {
			this.setEditMode();
		} else {
			this.setViewMode();
		}
	}

	hideButtonsWrapper() {
		this.buttonsWrapper.classList.add(`${this.cssClass}__buttons--hidden`);
	}

	showButtonsWrapper() {
		this.buttonsWrapper.classList.remove(
			`${this.cssClass}__buttons--hidden`
		);
	}

	setEditMode() {
		setContent(this.contentWrapper, this.editModeContent);
		setContent(this.buttonsWrapper, this.editModeButtons);
		if (!this.options.showEditButton) {
			this.showButtonsWrapper();
		}
		this.element.classList.remove(`${this.cssClass}--view-mode`);
		this.element.classList.add(`${this.cssClass}--edit-mode`);
	}

	setViewMode() {
		setContent(this.contentWrapper, this.viewModeContent);
		if (this.options.showEditButton) {
			setContent(this.buttonsWrapper, this.viewModeButtons);
		} else {
			this.hideButtonsWrapper();
		}
		this.element.classList.add(`${this.cssClass}--view-mode`);
		this.element.classList.remove(`${this.cssClass}--edit-mode`);
	}

	createEditModeContent() {
		this.createInput();
		this.editModeContent = [this.input];
	}

	createInput() {
		this.input = new Input({
			initialValue: this.options.value,
			extendedClasses: `${this.cssClass__input}`,
			onChange: value => {
				this.eventEmitter.emit('inputChange', value);
			}
		}).getEl();
	}

	createEditModeButtons() {
		this.saveButton = new Button({
			content: this.options.icons.save,
			extendedClasses: `${this.cssClass}__save-button`,
			onClick: () => {
				this.onSave();
			}
		}).getEl();
		this.discardButton = new Button({
			content: this.options.icons.discard,
			buttonVariant: 'danger',
			isOutline: true,
			extendedClasses: `${this.cssClass}__discard-button`,
			onClick: () => {
				this.onDiscard();
			}
		}).getEl();
		this.editModeButtons = [this.saveButton, this.discardButton];
	}

	createViewModeContent() {
		this.createLabel();
		this.viewModeContent = [this.label];
	}

	createLabel() {
		this.label = document.createElement('label');
		this.label.className = `eb-label ${this.cssClass}__label`;
		this.label.textContent = this.value;
		if (this.options.labelClickOpensEditMode) {
			this.label.classList.add(`${this.cssClass}__label--clickable`);
			this.label.addEventListener('click', () => {
				this.onEdit();
			});
		}
	}

	createViewModeButtons() {
		this.editButton = new Button({
			content: this.options.icons.edit,
			extendedClasses: `${this.cssClass}__edit-button`,
			onClick: () => {
				this.onEdit();
			}
		}).getEl();
		this.viewModeButtons = [this.editButton];
	}

	onSave() {
		this.setValue(this.input.value);
		if (typeof this.options.onSave === 'function') {
			this.options.onSave.call(this, this.input.value, this);
		}
		this.eventEmitter.emit('save');
		this.setViewMode();
	}

	onDiscard() {
		this.input.value = this.getValue();
		if (typeof this.options.onDiscard === 'function') {
			this.options.onDiscard.call(this, this);
		}
		this.eventEmitter.emit('discard');
		this.setViewMode();
	}

	onEdit() {
		this.setEditMode();
		if (typeof this.options.onEdit === 'function') {
			this.options.onEdit.call(this, this);
		}
		this.eventEmitter.emit('edit');
	}

	getEl() {
		return this.element;
	}

	getValue() {
		return this.value;
	}

	setValue(value) {
		this.value = value;
		this.label.textContent = this.value;
	}

	setType(type) {
		this.input.type = type;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}
